import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceBase } from "./servicebase";

@Injectable()
export class LogService extends ServiceBase {
  logServiceUrl = '';
  constructor(private http: HttpClient) {
    super();
    this.logServiceUrl = this.apiBaseUrl +  'logs';
  }

  logMessage(message){
    this.http.post(this.logServiceUrl, {'body': message}).subscribe();
  }
}
